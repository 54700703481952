.rotate {
  transform-box: fill-box;
  transform-origin: center;
  animation: 3s linear infinite rotate;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.svgText {
    font: 30px system-ui;
  }