.edgebutton {
  width: 40px;
  height: 40px;
  color: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 36px;
  line-height: 1;
  background: #005d8f;
  text-align: center;
  padding: 0px;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.circ {
  background: #000;
}

.edgebutton-foreignobject body {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

.react-flow__edge-path {
  stroke-width: 4;
}

.react-flow__attribution {
  display: none;
}