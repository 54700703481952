input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
input[type='number'] {
   -moz-appearance: textfield;
}

.menuItem:hover {
   background-color: rgb(200, 200, 200);
}
